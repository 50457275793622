/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
table.mat-table{
  width: 100%;
}
.hide{
  display: none!important;
}
.linkActiveTableRow{
  background-color: #edf5ffc7;
  outline: 1px solid black;
  
}
.text-right{
  text-align: right;
}